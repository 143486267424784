import filesize from 'filesize';

export class Trace implements TraceDataItem {

    public blurdelta: number;
    public blurradius: number;
    public colorquantcycles: number;
    public colorsampling: number;
    public corsenabled: boolean;
    public desc: boolean;
    public layering: number;
    public lcpr: number;
    public linefilter: boolean;
    public ltres: number;
    public mincolorratio: number;
    public numberofcolors: number;
    public pathomit: number;
    public qcpr: number;
    public qtres: number;
    public rightangleenhance: boolean;
    public roundcoords: number;
    public scale: number;
    public strokewidth: number;
    public viewbox: boolean;

    public static getColumnNames(): Array<string> {
        return [
            'width',
            'height',
            'fileSize',
            'numberOfNodes',

            'blurdelta',
            'blurradius',
            'colorquantcycles',
            'colorsampling',
            'corsenabled',
            'desc',
            'layering',
            'lcpr',
            'linefilter',
            'ltres',
            'mincolorratio',
            'numberofcolors',
            'pathomit',
            'qcpr',
            'qtres',
            'rightangleenhance',
            'roundcoords',
            'scale',
            'strokewidth',
            'viewbox',
        ];
    }

    constructor(
        public width: number,
        public height: number,
        public fileSize: number,
        public numberOfNodes: number,
        imageTracerPreset: ImageTracerPreset
    ) {
        this.width          = width;
        this.height         = height;
        this.fileSize       = fileSize;
        this.numberOfNodes  = numberOfNodes;

        this.blurdelta = imageTracerPreset.blurdelta;
        this.blurradius = imageTracerPreset.blurradius;
        this.colorquantcycles = imageTracerPreset.colorquantcycles;
        this.colorsampling = imageTracerPreset.colorsampling;
        this.corsenabled = imageTracerPreset.corsenabled;
        this.desc = imageTracerPreset.desc;
        this.layering = imageTracerPreset.layering;
        this.lcpr = imageTracerPreset.lcpr;
        this.linefilter = imageTracerPreset.linefilter;
        this.ltres = imageTracerPreset.ltres;
        this.mincolorratio = imageTracerPreset.mincolorratio;
        this.numberofcolors = imageTracerPreset.numberofcolors;
        this.pathomit = imageTracerPreset.pathomit;
        this.qcpr = imageTracerPreset.qcpr;
        this.qtres = imageTracerPreset.qtres;
        this.rightangleenhance = imageTracerPreset.rightangleenhance;
        this.roundcoords = imageTracerPreset.roundcoords;
        this.scale = imageTracerPreset.scale;
        this.strokewidth = imageTracerPreset.strokewidth;
        this.viewbox = imageTracerPreset.viewbox;
    }
}
