import { Buffer } from 'buffer';

export class SVG {

    private static readonly ATTRIBUTE_FILL = 'fill';
    private static readonly ATTRIBUTE_STROKE = 'stroke';

    private static readonly DEFAULT_FILL = '#FFFFFF';
    private static readonly DEFAULT_STROKE = '#000000';

    private static readonly HIGHLIGHT_FILL = '#FFFFFF';
    private static readonly HIGHLIGHT_STROKE = '#FF0000';

    private static readonly ORIGINAL_ATTRIBUTE_PREFIX = 'original-';

    private static readonly MIME_TYPE_SVG = 'image/svg+xml';
    private static readonly MIME_TYPE_XML = 'text/xml';

    svgXmlDocument: XMLDocument;
    rootNode: Node;

    static createFromString(xmlString: string): SVG {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, SVG.MIME_TYPE_XML);
        return new SVG(xmlDoc);
    }

    onMouseEnter = (event) => {
        this.highlight(event.target as Element);
    }

    onMouseLeave = (event) => {
        this.unHighlight(event.target as Element);
    }

    onClick = (event) => {

        const element = event.target as Element;

        if (!this.isRootNode(element)) {
            if (this.isOriginalFill(element)) {
                this.clearFill(element);
            } else {
                this.resetFill(element);
            }

            if (this.isOriginalStroke(element)) {
                this.clearStroke(element);
            } else {
                this.resetStroke(element);
            }
        }

        if (!this.isRootNode(element.parentElement)) {
            if (element.parentElement.hasAttribute(SVG.ATTRIBUTE_FILL)) {
                if (this.isOriginalFill(element.parentElement)) {
                    this.clearFill(element.parentElement);
                } else {
                    this.resetFill(element.parentElement);
                }
            }

            if (element.parentElement.hasAttribute(SVG.ATTRIBUTE_STROKE)) {
                if (this.isOriginalStroke(element.parentElement)) {
                    this.clearStroke(element.parentElement);
                } else {
                    this.resetStroke(element.parentElement);
                }
            }
        }
    }

    constructor(svgXmlDocument: XMLDocument) {
        this.svgXmlDocument = svgXmlDocument;
        this.initRootNode();
    }

    public getRootNode() {
        return this.rootNode;
    }

    public getAllChildren() {
        return this.getChildrenOfNode(this.getRootNode());
    }

    public toString() {
        return new XMLSerializer().serializeToString(this.svgXmlDocument.documentElement);
    }


    public getBlob(): Blob {
        return new Blob([this.toString()], {'type': SVG.MIME_TYPE_SVG + ';charset=utf8;'});
    }

    public getCountableNodesCount() {
        return this.getAllChildren().filter(node => {
            return !node.hasChildNodes() && node.nodeType === node.ELEMENT_NODE;
        }).length;
    }

    public clearAllFillAndStroke() {
        for (const svgElement of this.getAllChildren()) {
            if (svgElement instanceof Element) {
                this.clearFillAndStroke(svgElement);
            }
        }
    }

    public resetAllFillAndStroke() {
        for (const svgElement of this.getAllChildren()) {
            if (svgElement instanceof Element) {
                this.resetFillAndStroke(svgElement);
            }
        }
    }

    public initOnHover() {
        for (const svgElement of this.getAllChildren()) {
            if (svgElement instanceof Element) {
                if (!svgElement.hasChildNodes()) {
                    svgElement.addEventListener('mouseenter', this.onMouseEnter);
                    svgElement.addEventListener('mouseleave', this.onMouseLeave);
                }
            }
        }
    }

    public removeOnHover() {
        for (const svgElement of this.getAllChildren()) {
            if (svgElement instanceof Element) {
                if (!svgElement.hasChildNodes()) {
                    svgElement.removeEventListener('mouseenter', this.onMouseEnter);
                    svgElement.removeEventListener('mouseleave', this.onMouseLeave);
                }
            }
        }
    }

    public initOnClick() {
        this.getNode().addEventListener('click', this.onClick);
    }

    public removeOnClick() {
        this.getNode().removeEventListener('click', this.onClick);
    }

    public getNode() {
        return this.rootNode;
    }

    public getWidth() {
        return this.getRootNode().attributes.getNamedItem('width');
    }

     public getHeight() {
        return this.getRootNode().attributes.getNamedItem('height');
    }

    public addDefaultViewBox() {
        const rootElement = this.getRootNode() as Element;
        if (!rootElement.hasAttribute('viewBox')) {
            const width = this.getWidth();
            const height = this.getHeight();
            rootElement.setAttribute('viewBox', '0 0 ' + width.value + ' ' + height.value);
        }
    }

    /**
     * Returns filesize in bytes
     *
     * @returns {number}
     */
    public getFileSize() {
        return Buffer.byteLength(this.toString(), 'utf8');
    }

    private highlight(node: Element) {
        this.changeAttribute(node, SVG.ATTRIBUTE_FILL, SVG.HIGHLIGHT_FILL);
        this.changeAttribute(node, SVG.ATTRIBUTE_STROKE, SVG.HIGHLIGHT_STROKE);
    }

    private unHighlight(node: Element) {
        this.resetAttribute(node, SVG.ATTRIBUTE_FILL);
        this.resetAttribute(node, SVG.ATTRIBUTE_STROKE);
    }

    private isRootNode(node: Element) {
        return this.getRootNode() === node;
    }

    private isOriginalFill(node: Element) {
        return this.isOriginalAttributeValue(node, SVG.ATTRIBUTE_FILL);
    }

    private isOriginalStroke(node: Element) {
        return this.isOriginalAttributeValue(node, SVG.ATTRIBUTE_STROKE);
    }

    private clearFill(node: Element) {
        this.changeAttribute(node, SVG.ATTRIBUTE_FILL, SVG.DEFAULT_FILL);
    }

    private resetFill(node: Element) {
        this.resetAttribute(node, SVG.ATTRIBUTE_FILL);
    }

    private clearStroke(node: Element) {
        this.changeAttribute(node, SVG.ATTRIBUTE_STROKE, SVG.DEFAULT_STROKE);
    }

    private resetStroke(node: Element) {
        this.resetAttribute(node, SVG.ATTRIBUTE_STROKE);
    }

    private resetAttribute(node: Element, attribute: string) {
        if (this.isOriginalAttributeValue(node, attribute)) {
            return;
        }
        const originalAttributeName = SVG.ORIGINAL_ATTRIBUTE_PREFIX + attribute;
        if (node.hasAttribute(originalAttributeName)) {
            const oldStroke = node.getAttribute(originalAttributeName);
            node.removeAttribute(originalAttributeName);
            node.setAttribute(attribute, oldStroke);
        }
    }

    private changeAttribute(node: Element, attribute: string, value: string) {
        if (this.isOriginalAttributeValue(node, attribute)) {
            const originalAttributeName = SVG.ORIGINAL_ATTRIBUTE_PREFIX + attribute;
            if (!node.hasAttribute(originalAttributeName)) {
                node.setAttribute(originalAttributeName, node.getAttribute(attribute));
            }
            node.setAttribute(attribute, value);
        }
    }

    private isOriginalAttributeValue(node: Element, attribute: string) {
        const originalAttributeName = SVG.ORIGINAL_ATTRIBUTE_PREFIX + attribute;
        if (!node.hasAttribute(attribute) || !node.hasAttribute(originalAttributeName)) {
            return true;
        }
        return node.getAttribute(originalAttributeName) === node.getAttribute(attribute);
    }


    private clearFillAndStroke(node: Element) {
        this.clearFill(node);
        this.clearStroke(node);
    }

    private resetFillAndStroke(node: Element) {
        this.resetFill(node);
        this.resetStroke(node);
    }

    /**
     * Initializes a DOM node by string:
     * source: https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro
     *
     */
    private initRootNode() {
        const div = document.createElement('div');
        div.innerHTML = this.toString().trim();

        // Change this to div.childNodes to support multiple top-level nodes
        this.rootNode = div.firstChild;
    }

    /**
     * TODO: Refactor this. we should filter only nodes of type xml element
     * return []
     **/
    private getChildrenOfNode(node: Node) {
        const allChildren = [];
        for (const childNode of Array.from(node.childNodes)) {
            allChildren.push(childNode);
            for (const childChildNode of this.getChildrenOfNode(childNode)) {
                allChildren.push(childChildNode);
            }
        }

        return allChildren;
    }
}
