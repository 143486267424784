import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule, MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule
} from '@angular/material';
import { FormsModule } from '@angular/forms';
import { FilesizePipe } from './pipe/filesize.pipe';



@NgModule({
    declarations: [
        AppComponent,
        FilesizePipe
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatCardModule,
        MatGridListModule,
        MatListModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatTableModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTabsModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatRadioModule,
        FormsModule,
        MatExpansionModule,
        MatInputModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
