export class RasterImage {

    private dataUrl = '';
    private width = 0;
    private height = 0;

    constructor(private rasterImageFile: File) { }

    getFile(): File {
        return this.rasterImageFile;
    }

    getDataUrl(): string {
        return this.dataUrl;
    }

    getWidth(): number {
        return this.width;
    }

    getHeight(): number {
        return this.height;
    }

    initData(): Promise<boolean> {
        const promise = new Promise<boolean>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (readAsDataUrlEvent: any) => {
                this.setDataUrl(readAsDataUrlEvent.target.result);
                const imageElement = document.createElement('img');
                imageElement.onload = (loadImageEvent: any) => {
                    this.width  = loadImageEvent.path[0].width;
                    this.height = loadImageEvent.path[0].height;
                    resolve(true);
                };
                imageElement.src = this.dataUrl;

            };
            reader.readAsDataURL(this.getFile());
        });

        return promise;
    }

    public setDataUrl(dataUrl: string): void {
        this.dataUrl = dataUrl;
    }
}
