import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SVG } from './model/svg.model';
import { HttpClient } from '@angular/common/http';
import * as ImageTracer from 'node_modules/imagetracerjs/imagetracer_v1.2.4.js';
import { ReduceImageSizePreset } from './model/reduce-image-size-preset.model';
import readAndCompressImage from 'browser-image-resizer';
import filesize from 'filesize';
import { Trace } from './model/trace.model';
import * as cloneDeep from 'lodash/cloneDeep';
import { RasterImage } from './model/raster-image.model';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit {

    private static TAB_INDEX_SVG = 0;
    private static TAB_INDEX_RASTER_IMAGE = 1;

    private static DEFAULT_SVG_FILE = 'assets/default.svg';
    private static DEFAULT_RASTER_IMAGE_FILE = 'assets/default.jpg';

    traceLog: Array<Trace> = [];

    svg: SVG = null;
    rasterImageDataUrl = null;
    rasterImageFile: File = null;
    rasterImage: RasterImage = null;
    svgContainer: HTMLElement = null;

    addDefaultViewBox = true;
    onHover = false;
    onClick = false;

    tabIndices = [
        AppComponent.TAB_INDEX_SVG,
        AppComponent.TAB_INDEX_RASTER_IMAGE
    ];

    selectedTabIndex = AppComponent.TAB_INDEX_SVG;

    imageTracerPresets = [
        {name: 'Default', value: ImageTracer.optionpresets.default},
        {name: 'Posterized 1', value: ImageTracer.optionpresets.posterized1},
        {name: 'Posterized 2', value: ImageTracer.optionpresets.posterized2},
        {name: 'Posterized 3', value: ImageTracer.optionpresets.posterized3},
        {name: 'Curvy', value: ImageTracer.optionpresets.curvy},
        {name: 'Sharp', value: ImageTracer.optionpresets.sharp},
        {name: 'Detailed', value: ImageTracer.optionpresets.detailed},
        {name: 'Smoothed', value: ImageTracer.optionpresets.smoothed},
        {name: 'Grayscale', value: ImageTracer.optionpresets.grayscale},
        {name: 'Fixed Palette', value: ImageTracer.optionpresets.fixedpalette},
        {name: 'Random Sampling 1', value: ImageTracer.optionpresets.randomsampling1},
        {name: 'Random Sampling 2', value: ImageTracer.optionpresets.randomsampling2},
        {name: 'Artistic 1', value: ImageTracer.optionpresets.artistic1},
        {name: 'Artistic 2', value: ImageTracer.optionpresets.artistic2},
        {name: 'Artistic 3', value: ImageTracer.optionpresets.artistic3},
        {name: 'Artistic 4', value: ImageTracer.optionpresets.artistic4},
    ];
    selectedImageTracerPreset = this.imageTracerPresets[0].value;

    reduceImageSize = false;
    customImageSizePreset = new ReduceImageSizePreset(1, 1);
    reduceImageSizePresets = [
        {name: '1920 x 1080', value: new ReduceImageSizePreset(1920, 1080)},
        {name: '960 x 540', value: new ReduceImageSizePreset(960, 540)},
        {name: '100 x 100', value: new ReduceImageSizePreset(100, 100)},
        {name: '300 x 300', value: new ReduceImageSizePreset(300, 300)},
        {name: '500 x 500', value: new ReduceImageSizePreset(500, 500)},
        {name: '800 x 600', value: new ReduceImageSizePreset(800, 600)},
        {name: 'Custom', value: this.customImageSizePreset},
    ];
    selectedReduceImageSizePreset = this.reduceImageSizePresets[0].value;

    constructor(
        private domSanitizer: DomSanitizer,
        private httpClient: HttpClient,
        private elementRef: ElementRef
    ) {
        if (this.svg === null) {
            this.httpClient.get(AppComponent.DEFAULT_SVG_FILE, {responseType: 'text'})
                .subscribe((data: any) => {
                    this.setSVG(SVG.createFromString(data));
                });
        }

        if (this.rasterImageFile === null) {
            this.httpClient.get(AppComponent.DEFAULT_RASTER_IMAGE_FILE, {responseType: 'blob'})
                .subscribe((imageBlob: Blob) => {
                    const file = new File(
                        [imageBlob],
                        AppComponent.DEFAULT_RASTER_IMAGE_FILE,
                        {type: imageBlob.type}
                    );

                    const rasterImage = new RasterImage(file);
                    rasterImage.initData()
                        .then((result) => {
                            this.setRasterImage(rasterImage);
                        });
                });
        }
    }

    ngAfterViewInit() {
        // Only working because the app is initialized with the first tab
        this.svgContainer           = this.elementRef.nativeElement.querySelector('.svg');
    }

    public onChangeSVG(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.setSVG(SVG.createFromString(e.target.result));
            };
            reader.readAsText(fileInput.target.files[0]);
        }
    }

    public onChangeRasterImage(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            const rasterImage = new RasterImage(fileInput.target.files[0]);
            rasterImage.initData()
                .then((result) => {
                    this.setRasterImage(rasterImage);
                });
        }
    }

    public clearFillAndStroke() {
        this.svg.clearAllFillAndStroke();
    }

    public resetFillAndStroke() {
        this.svg.resetAllFillAndStroke();
    }

    public downloadLog() {
        const logs = this.getTableDataLogs();
        const csv = new Angular5Csv(logs, 'painty-svg-log', {
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Painty SVG Log - ' + new Date().toUTCString(),
            useBom: true,
            noDownload: false,
            headers: this.getTableLogsColumnNames()
        });
    }

    /**
     * rasterImageDataUrl must be a data URL or a resource url
     *
     *
     * @param {string} rasterImageDataUrl
     */
    private traceRasterImage(rasterImage: RasterImage) {
        ImageTracer.imageToSVG(
            rasterImage.getDataUrl(),
            (svgString) => {
                this.setSVG(SVG.createFromString(svgString));
                this.changeTab(AppComponent.TAB_INDEX_SVG);

                this.logTrace(rasterImage);
            },
            this.getImageTracerOptions()
        );
    }

    private logTrace(rasterImage: RasterImage) {
        this.traceLog.unshift(
            new Trace(
                rasterImage.getWidth(),
                rasterImage.getHeight(),
                this.svg.getFileSize(),
                this.svg.getCountableNodesCount(),
                cloneDeep(this.selectedImageTracerPreset)
            )
        );
    }

    private getReduceImageSizeConfig() {
        return {
            quality: 1.0,
            maxWidth: this.selectedReduceImageSizePreset.width,
            maxHeight: this.selectedReduceImageSizePreset.height
        };
    }

    public traceRasterImageAction() {

        if (this.reduceImageSize) {
            readAndCompressImage(
                this.getRasterImageFile(),
                this.getReduceImageSizeConfig()
            ).then(resizedImage => {

                const file = new File(
                    [resizedImage],
                    AppComponent.DEFAULT_RASTER_IMAGE_FILE,
                    {type: resizedImage.type}
                );

                const rasterImage = new RasterImage(file);
                rasterImage.initData()
                    .then((result) => {
                        this.traceRasterImage(rasterImage);
                    });

            });
        } else {
            this.traceRasterImage(this.rasterImage);
        }
    }

    public changeOnHover() {
        this.onHover = !this.onHover;
        this.initOnHover();
    }

    public changeOnClick() {
        this.onClick = !this.onClick;
        this.initOnClick();
    }

    public initOnHover() {
        if (this.onHover) {
            this.svg.initOnHover();
        } else {
            this.svg.removeOnHover();
        }
    }

    public initOnClick() {
        if (this.onClick) {
            this.svg.initOnClick();
        } else {
            this.svg.removeOnClick();
        }
    }

    public setSVG(svg: SVG) {
        this.svg = svg;

        this.initOnHover();
        this.initOnClick();

        if (this.addDefaultViewBox) {
            this.svg.addDefaultViewBox();
        }

        if (this.svgContainer.childNodes.length > 0) {
            this.svgContainer.replaceChild(this.svg.getNode(), this.svgContainer.childNodes.item(0));
        } else {
            this.svgContainer.appendChild(this.svg.getNode());
        }
    }

    public setRasterImageDataUrl(dataUrl: string) {
        this.rasterImageDataUrl = dataUrl;
    }

    /**
     * The raster image as an URL. So it can be assets/default.jpg or data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWgAAAIcCAYAAAD...
     * @returns {any}
     */
    public getRasterImageDataUrl() {
        if (this.rasterImageDataUrl === null) {
            return AppComponent.DEFAULT_RASTER_IMAGE_FILE;
        }

        return this.rasterImageDataUrl;
    }

    public getRasterImageFile(): File {
        return this.rasterImage.getFile();
    }

    public setRasterImage(rasterImage: RasterImage) {
        this.rasterImage = rasterImage;
        this.setRasterImageDataUrl(rasterImage.getDataUrl());
    }

    public isTableActive() {
        return this.svg !== null;
    }

    public getSVGDownload() {
        this.executeFileDownload(this.svg.getBlob(), 'painty-svg.svg');
    }

    private executeFileDownload(blob: Blob, filename: string) {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.setAttribute('visibility', 'hidden');
        element.download = filename;

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    public getTableData() {
        const data: SVGDataItem[] = [
            {name: 'Nodes', value: this.svg.getCountableNodesCount().toString()},
            {name: 'Size', value: this.getFileSize()},
        ];
        return data;
    }

    /**
     *
     * @returns {TraceDataItem[]}
     */
    public getTableDataLogs() {
        const data: TraceDataItem[] = cloneDeep(this.traceLog);
        return data;
    }

    private getFileSize() {
        return filesize(this.svg.getFileSize());
    }

    public getTableColumnNames(): Array<string> {
        return ['name', 'value'];
    }

    public getTableLogsColumnNames(): Array<string> {
        return Trace.getColumnNames();
    }

    private changeTab(tabIndex: number) {
        if (this.tabIndices.indexOf(tabIndex) === -1) {
            return;
        }
        this.selectedTabIndex = tabIndex;
    }

    private getImageTracerOptions() {
        return this.selectedImageTracerPreset;
    }

    private isCustomSelectedImageSizePreset() {
        return this.selectedReduceImageSizePreset === this.customImageSizePreset;
    }


}
